import React, { ReactElement } from "react"

const Input = React.forwardRef(
  (
    {
      className,
      ...rest
    }: React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    ref: any
  ): ReactElement => {
    return (
      <input
        {...rest}
        className={`appearance-none border border-transparent w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-md rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-br-primary-blue focus:border-transparent ${className}`}
        ref={ref}
      />
    )
  }
)

export default Input
