import React, { ReactElement, useState } from "react"
import DefaultLayout from "../templates/DefaultLayout"
import Auth from "@aws-amplify/auth"

import { toast } from "react-toastify"
import Button from "../components/Button/Button"
import Input from "../components/Input/Input"
import { navigate } from "gatsby"

export default function ForgotPassword(): ReactElement {
  const [username, setUsername] = useState("")
  const [resetMode, setResetMode] = useState(true)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [password, setPassword] = useState("")
  const [code, setCode] = useState("")
  const resetPassword = () => {
    const toastId = toast("Loading....", {
      progressClassName: "bg-brand-primary  ",
      progress: "0",
    })
    Auth.forgotPassword(username)
      .then(() => {
        toast.dismiss(toastId)
        toast(
          "A new verification email has been sent to the provided email address.",
          {
            type: "info",
          }
        )
        setResetMode(false)
      })
      .catch(e => {
        toast.dismiss(toastId)

        toast(e.message, {
          type: "error",
        })
      })
  }

  const changePassword = () => {
    const toastId = toast("Loading....", {
      progressClassName: "bg-brand-primary  ",
      progress: "0",
    })
    Auth.forgotPasswordSubmit(username, code.trim(), password.trim())
      .then(() => {
        toast.dismiss(toastId)
        toast("Password changed successfully. Please login to continue.", {
          type: "info",
        })
        setTimeout(() => {
          navigate("/sign-in")
        }, 2000)
      })
      .catch(e => {
        toast.dismiss(toastId)
        toast(e.message, {
          type: "error",
        })
      })
  }
  return (
    <DefaultLayout>
      <div className="min-h-[80vh] flex  justify-center items-start">
        <div className="max-w-[500px] w-full lg:p-10 p-5 rounded-md border my-4 bg-br-primary-blue shadow-lg bg-opacity-5 mx-4">
          <h2 className="font-bold text-xl lg:text-3xl text-br-primary-blue">
            Reset Password
          </h2>

          {resetMode && (
            <>
              <Input
                placeholder="Email"
                className="mt-10"
                autoComplete="off"
                value={username}
                onChange={({ target: { value } }) => setUsername(value)}
              />

              <Button
                className="w-full mt-10 bg-brand-secondary py-4 rounded-full text-brand-white"
                onClick={resetPassword}
              >
                Reset Password
              </Button>
            </>
          )}

          {!resetMode && (
            <>
              <div
                className="underline text-brand-primary cursor-pointer mt-6 text-br-primary-blue"
                onClick={resetPassword}
              >
                Resend Verification Code
              </div>
              <p className="pt-4 text-gray-500">
                A password rest code has been sent to the user email. Please
                enter the code and the new password.
              </p>
              <Input
                placeholder="Code"
                autoComplete="off"
                type="text"
                className="mt-6"
                name="code"
                value={code}
                onChange={({ target: { value } }) => setCode(value)}
              />
              <form>
                <Input
                  placeholder="Password"
                  type="password"
                  autoComplete="off"
                  name="password"
                  className="mt-6"
                  value={password}
                  onChange={({ target: { value } }) => setPassword(value)}
                />
                <Input
                  placeholder="Confirm Password"
                  type="password"
                  autoComplete="off"
                  name="confirmPassword"
                  className="mt-6"
                  value={confirmPassword}
                  onChange={({ target: { value } }) =>
                    setConfirmPassword(value)
                  }
                />
              </form>
              <ol className="list-disc mt-4 ml-10 text-br-primary-blue">
                <li className="mt-2">
                  Password must contain a lower case letter
                </li>
                <li className="mt-2">
                  Password must contain an upper case letter
                </li>
                <li className="mt-2">
                  Password must contain a special character
                </li>
                <li className="mt-2">Password must contain a number</li>
                <li className="mt-2">
                  Password must contain at least 8 characters
                </li>
              </ol>
              <Button
                className="w-full mt-10 bg-brand-secondary py-4 rounded-full text-brand-white"
                onClick={changePassword}
              >
                Change Password
              </Button>
            </>
          )}
        </div>
      </div>
    </DefaultLayout>
  )
}
